<template>
  <div>
    <a-modal
      v-model="visibleModalManageCoreCompetence"
      class="mod"
      :title="
        status.isEdit
          ? 'Form Edit Core Competence'
          : 'Form Create Core Competence'
      "
      @cancel="toggleModalManageCoreCompetence"
      centered
    >
      <form-core-competence
        :dataCoreCompetence="newCoreCompetence"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button
          size="large"
          key="back"
          @click="toggleModalManageCoreCompetence"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageCoreCompetence"
          @click="handleOkModalManageCoreCompetence"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageCoreCompetence('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Core Competence</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="coreCompetenceColumns"
        :data-source="coreCompetenceDataTable"
        :pagination="coreCompetencePagination"
        :loading="coreCompetenceLoadingTable"
        bordered
        @change="handleTableChange"
      >
        <div
          slot="mata_pelajaran"
          slot-scope="value, record"
          class="d-flex flex-column"
        >
          <p>{{ record.level.nama }}</p>
          <span>{{ record.mata_pelajaran.nama }}</span>
        </div>
        <div
          slot="action"
          slot-scope="value, record"
          class="d-flex flex-column justify-content-center"
        >
          <a-button
            @click.prevent="toggleModalManageCoreCompetence('edit', record)"
            class="mb-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteCoreCompetence(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const formCoreCompetence = () => import('@/components/app/Admin/Master/Form/CoreCompetence')

const coreCompetenceColumns = [
  {
    title: 'Subject',
    dataIndex: 'mata_pelajaran',
    key: 'mata_pelajaran',
    scopedSlots: { customRender: 'mata_pelajaran' },
  },
  {
    title: 'Type',
    dataIndex: 'tipe',
    key: 'tipe',
    scopedSlots: { customRender: 'tipe' },
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'CoreCompetence',
  components: {
    formCoreCompetence,
  },
  data() {
    return {
      coreCompetenceColumns,
      visibleModalManageCoreCompetence: false,
      loadingActionModalManageCoreCompetence: false,
      loadingDeleteCoreCompetence: false,
      coreCompetenceLoadingTable: false,
      coreCompetenceDataTable: [],
      coreCompetencePagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newCoreCompetence: {
        id_level: null,
        id_mata_pelajaran: null,
        tipe: null,
        nama: null,
      },
      idEdit: null,
      search: '',
      sortBy: '',
      order: '',
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      console.log('parent', payload)
      this.newCoreCompetence[column] = value
    },
    toggleModalManageCoreCompetence(status, data) {
      this.visibleModalManageCoreCompetence = !this.visibleModalManageCoreCompetence
      if (!this.visibleModalManageCoreCompetence) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newCoreCompetence = {
            id_level: null,
            id_mata_pelajaran: null,
            tipe: null,
            nama: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newCoreCompetence = {
            id_level: data.id_level,
            id_mata_pelajaran: data.id_mata_pelajaran,
            tipe: data.tipe,
            nama: data.nama,
          }
        }
      }
    },
    handleOkModalManageCoreCompetence() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this core competence?' : 'Are you sure want to create new core competence'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageCoreCompetence = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataCoreCompetence: this.newCoreCompetence,
            } : {
              dataCoreCompetence: this.newCoreCompetence,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_CORE_COMPETENCE`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageCoreCompetence = false
                this.toggleModalManageCoreCompetence()
                this.fetchDataCoreCompetence()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Core Competence has been updated' : 'Core Competence has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Core Competence has not been updated' : 'Core Competence has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteCoreCompetence(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this core competence?</div>
        ),
        onOk: () => {
          this.classLoadingTable = true
          this.loadingDeleteCoreCompetence = true
          this.$store.dispatch('master/DELETE_CORE_COMPETENCE', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataCoreCompetence()
              this.loadingDeleteCoreCompetence = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Core Competence has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Core Competence has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteCoreCompetence = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    handleSearch(e) {
      this.search = e.target.value
      this.fetchDataCoreCompetence()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pendingPagination }
      this.sortBy = sorter.columnKey
      pager.current = pagination.current
      this.pendingPaginationpagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchDataCoreCompetence()
    },
    fetchDataCoreCompetence() {
      this.coreCompetenceLoadingTable = true
      this.$store.dispatch('master/FETCH_ALL_CORE_COMPETENCE', { page: this.coreCompetencePagination.page || 1, order: this.order, search: this.search, sortBy: this.sortBy })
        .then(res => {
          this.coreCompetenceLoadingTable = false
          const pagination = { ...this.coreCompetencePagination }
          pagination.total = res.total
          this.coreCompetencePagination = pagination
          this.coreCompetenceDataTable = res.dataTable.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataCoreCompetence()
  },
  computed: {
    isValidateForm() {
      if (this.newCoreCompetence.id_mata_pelajaran && this.newCoreCompetence.id_level && this.newCoreCompetence.tipe && this.newCoreCompetence.nama) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
